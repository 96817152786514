@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Sora:wght@100..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollmagic-pin-spacer {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
